<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import contracts from "./contracts";
/**
 * Starter component
 */
export default {
  page: {
    title: "Ordonnancement",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, contracts },
  data() {
    return {
      title: "Ordonnancement",
      items: [
        {
          text: "Suivi & Gestion",
          to: {name: 'monitoring_and_management'},
        },
        {
          text: "Ordonnancement",
          active: true,
        },
      ],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <contracts/>
  </Layout>
</template>
<template>
  <div>
    <h3></h3>
    
    <hr class="mb-1" />
    <div class="row">
      <div class="col-md-12">
        <div class="float-right" style="font-size: 18px">
          <a v-b-popover.hover="`Activer le mode KANBAN`" @click.prevent="showKanbanView" :class="showKanban ? 'activeActionLink' : ''" href=""><i class="fab fa-trello"></i></a>
          <a v-b-popover.hover="`Activer le mode TABLEAU`" @click.prevent="showListView" :class="showTable ? 'activeActionLink' : ''" href=""><i class="fas fa-list ml-2"></i></a>
        </div>
      </div>
    </div>
    <KanbanView v-if="showKanban" :contracts="contracts"/>
    <ListView v-if="showTable" :contracts="contracts"/>

  </div>
</template>

<script>
import users from "./test.json";
import {
  filterList,
  isoDateToEuroDate,
  searchAsEuroDate,
} from "./utilities";

import KanbanView from "./components/contracts/list/kanban";
import ListView from "./components/contracts/list/list";
import { fetchContractsListApi } from "@/api/common";

export default {
  name: "ContractsList",
  components: {
    KanbanView,
    ListView
  },
  import : {fetchContractsListApi},
  data: function() {
    return {
      users: users,
      showKanban : true,
      showTable : false,
      contracts: [],
      showNesInvoiceModal: false,
      startsWith: "",
      onlineFilter: "",
      statusClass: {
        Active: "text-success",
        Away: "text-warning",
        "Do not disturb": "text-danger",
        Invisible: "text-secondary",
      },
      firstNameAsc: true,
    };
  },
  computed: {
    sortFirstName() {
      return this.firstNameAsc ? "firstName" : "-firstName";
    },
  },
  mounted() {
    this.fetchContractsList();
  },

  methods: {
    filterList,
    isoDateToEuroDate,
    searchAsEuroDate,

    showKanbanView(){
      this.showKanban = true;
      this.showTable = false;
    },
    showListView(){
      this.showKanban = false;
      this.showTable = true;
    },

    fetchContractsList() {
      var loader = this.$loading.show();
      fetchContractsListApi('approved')
      .then((res) => {
        loader.hide();
        this.contracts = res.data.list
      })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {
          //Perform action in always
        });
    },

    showNewInvoiceModalClick() {
      this.showNesInvoiceModal = true;
    },

    handleCloseNewInvoiceModalEvent() {
      this.showNesInvoiceModal = false;
    },
  },
};
</script>

<template>
  <div>
    <dataset
      v-slot="{ ds }"
      :ds-data="contracts"
      :ds-sortby="sortBy"
      :ds-search-in="['ref', 'supplier', 'prest_type']"
    >
      <div class="row">
        <div class="col-md-6 mb-2 mb-md-0">
          <dataset-show />
        </div>
        <div class="col-md-6">
          <dataset-search ds-search-placeholder="Recherche..." :wait="300" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped d-md-table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th
                    v-for="(th, index) in cols"
                    :key="th.field"
                    :class="['sort', th.sort]"
                    @click="click($event, index)"
                  >
                    {{ th.name }} <i class="gg-select float-right"></i>
                  </th>
                </tr>
              </thead>
              <dataset-item tag="tbody">
                <template #default="{ row, rowIndex }">
                  <tr>
                    <th scope="row">{{ rowIndex + 1 }}</th>
                    <td>
                      <router-link
                        :to="{
                          name: 'invoicing.contract.invoices',
                          params: { uid: row.uuid },
                        }"
                      >
                        {{ row.ref }}
                      </router-link>
                    </td>
                    <td>{{ row.supplier.name }}</td>
                    <td>{{ row.type }}</td>
                    <td>{{ row.amounts.planned_amount.withCurrency }}</td>
                    <td>##.##</td>
                    <td>##.## %</td>
                  </tr>
                </template>
              </dataset-item>
            </table>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-md-row flex-column justify-content-between align-items-center"
      >
        <dataset-info class="mb-2 mb-md-0" />
        <dataset-pager />
      </div>
    </dataset>
  </div>
</template>

<style lang="scss" scoped>
.gg-select {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-select::after,
.gg-select::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 7px;
  transform: rotate(-45deg);
}
.gg-select::before {
  border-left: 2px solid;
  border-bottom: 2px solid;
  bottom: 4px;
  opacity: 0.3;
}
.gg-select::after {
  border-right: 2px solid;
  border-top: 2px solid;
  top: 4px;
  opacity: 0.3;
}
th.sort {
  cursor: pointer;
  user-select: none;
  &.asc {
    .gg-select::after {
      opacity: 1;
    }
  }
  &.desc {
    .gg-select::before {
      opacity: 1;
    }
  }
}
</style>

<script>
import {
  isoDateToEuroDate,
  isoDateToDate,
  searchAsEuroDate,
} from "../../../utilities";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
import DatasetInfo from "vue-dataset/dist/es/DatasetInfo.js";
import DatasetPager from "vue-dataset/dist/es/DatasetPager.js";
import DatasetSearch from "vue-dataset/dist/es/DatasetSearch.js";
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";
export default {
  props: {
    contracts: {},
  },
  components: {
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    DatasetShow,
  },
  name: "ContractListView",
  data: function() {
    return {
      cols: [
        {
          name: "Référence",
          field: "ref",
          sort: "",
        },
        {
          name: "Prestataire",
          field: "supplier",
          sort: "",
        },
        {
          name: "Type de prestation",
          field: "prest_type",
          sort: "",
        },
        {
          name: "Montant alloué",
          field: "allocated_amnt",
          sort: "",
        },
        {
          name: "Montant Disponible",
          field: "available_amnt",
          sort: "",
        },
        {
          name: "Réalisation",
          field: "relization",
          sort: "",
        },
      ],
    };
  },
  computed: {
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === "asc" ? acc.push(o.field) : acc.push("-" + o.field);
        }
        return acc;
      }, []);
    },
  },
  methods: {
    click(event, i) {
      let toset;
      const sortEl = this.cols[i];
      if (!event.shiftKey) {
        this.cols.forEach((o) => {
          if (o.field !== sortEl.field) {
            o.sort = "";
          }
        });
      }
      if (!sortEl.sort) {
        toset = "asc";
      }
      if (sortEl.sort === "desc") {
        toset = event.shiftKey ? "" : "asc";
      }
      if (sortEl.sort === "asc") {
        toset = "desc";
      }
      sortEl.sort = toset;
    },
    isoDateToEuroDate,
    isoDateToDate,
    searchAsEuroDate,
  },
};
</script>
